import type { MouseEvent } from 'react'
import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { App } from 'redesign/components'

import { message } from 'components/Message'

import { TableActions } from 'redesign/components/TableActions'

import { useProfileTechEvaluations } from 'redesign/hooks/useProfileTechEvaluations'
import { useUser } from 'redesign/hooks/useUser'

import type { TechEvaluationsCustomHookData, TechEval } from 'redesign/types/TechEvaluationsSmartlist'

const convertToModalData = (e: TechEval) => {
  const { nameEmail, author } = e
  const skills = e?.qualifiedSkills || []
  return {
    id: e.id,
    author: {
      id: author?.id,
      username: author?.userName,
      email: author?.email,
      profileURL: author?.profileURL
    },
    body: e.wholeText,
    expert: {
      id: e.expert || 0,
      fullName: e?.expertName
    },
    profile: nameEmail?.profileId,
    recording: e?.recording,
    skills: skills,
    supportedByExpert: e.supportedByExpert,
    roleFit: e.roleFit,
    xteamFit: e.xteamFit || false,
    role_candidate: e.roleCandidate,
    // eslint-disable-next-line camelcase
    created_at: e.createdAt,
    // eslint-disable-next-line camelcase
    updated_at: e.updatedAt,
    // eslint-disable-next-line camelcase
    published_at: e.publishedAt
  }
}

type Props = {
  record: TechEval
  customHookData: TechEvaluationsCustomHookData
}

export const TechEvaluationActions = (props: Props) => {
  const { modal: { confirm } } = App.useApp()
  const { data: user } = useUser()
  const { record, customHookData } = props
  const {
    setProfileId,
    setSelectedTechEvaluation,
    openModal
  } = customHookData

  const { deleteTechEvaluation } = useProfileTechEvaluations({
    extraQueryKeysToInvalidate: ['smartlistData']
  })

  const onDelete = (event: MouseEvent, techEvaluationId: string) => {
    event.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this Technical Evaluation?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        if (!record?.author?.id) {
          message.error('Delete failed: evaluation author is undefined.')
          return
        }
        deleteTechEvaluation(techEvaluationId)
      }
    })
  }

  return (
    <TableActions
      user={user}
      record={record}
      onClickEdit={event => {
        event.stopPropagation()
        setProfileId(record?.nameEmail?.profileId)
        setSelectedTechEvaluation(convertToModalData(record))
        openModal()
      }}
      onDelete={event => onDelete(event, String(record.id))}
      canUpdate={Boolean(user.role.capabilities.canUpdateAnyTechEval)}
      tableName="techEvaluations"
    />
  )
}
