import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Modal } from 'components/Modal'

import { TransferModalContent } from 'redesign/components/RoleCandidatesTransforModal/TransferModalContent'

import { usePartnerRoleCandidates } from 'redesign/hooks/usePartnerRoleCandidates'
import { usePartnerRoles } from 'redesign/hooks/usePartnerRoles'
import type { TransferParams } from 'redesign/hooks/useRoleCandidates/useRoleCandidate.types'
import { useUser } from 'redesign/hooks/useUser'

import { mapPartnerRolesToSelectOptions } from 'redesign/utils/partnerRoleUtils'
import { ORIGINS } from 'redesign/utils/roleCandidate'

import type { MappedPartnerRole } from 'redesign/types/MappedPartnerRole'

type TransferModalProps = {
  profileId: number
  roleCandidateId: number
  partnerRoleId: number
  isTransferRoleCandidateModalOpen: boolean
  setIsTransferRoleCandidateModalOpen: (
    value: React.SetStateAction<boolean>
  ) => void
  transferRoleCandidateMutation: (params: TransferParams) => void
}

export const TransferModal = (props: TransferModalProps) => {
  const {
    profileId,
    roleCandidateId,
    partnerRoleId,
    isTransferRoleCandidateModalOpen,
    setIsTransferRoleCandidateModalOpen,
    transferRoleCandidateMutation
  } = props

  const { data: authData } = useUser()
  const {
    isLoading,
    data: partnerRoles,
    mappedPartnerRoles
  } = usePartnerRoles()
  const bookedParams = {
    profile: profileId,
    closedAt_null: true,
    bookedAt_null: false
  }
  const { isLoading: isLoadingBooked, data: bookedPartnerRoles } =
    usePartnerRoleCandidates({ params: bookedParams })
  const bookedPartnerRoleId = useMemo(() => {
    if (bookedPartnerRoles.length > 0) {
      const [first] = bookedPartnerRoles
      return first?.partnerRole?.id
    }
  }, [bookedPartnerRoles])
  const [filteredPartnerRoles, setFilteredPartnerRoles] =
    useState<MappedPartnerRole[]>(mappedPartnerRoles)
  useEffect(() => {
    const setPartnerRoles = (mappedPartnerRoles: MappedPartnerRole[]) => {
      const filtered = mappedPartnerRoles.filter(
        ({ value }) => value !== partnerRoleId && value !== bookedPartnerRoleId
      )
      setFilteredPartnerRoles(filtered)
    }
    setPartnerRoles(mappedPartnerRoles)
  }, [bookedPartnerRoleId, mappedPartnerRoles, partnerRoleId])
  const [newPartnerRoleId, setNewPartnerRoleId] = useState<number>()
  const handleOk = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    if (newPartnerRoleId) {
      await transferRoleCandidateMutation({
        roleCandidateId,
        newPartnerRoleId,
        origin: {
          type: ORIGINS.TRANSFER.type,
          roleFrom: partnerRoleId,
          roleTo: newPartnerRoleId,
          user: authData.username
        }
      })
    }
    setIsTransferRoleCandidateModalOpen(false)
  }
  const handleCancel = () => {
    setIsTransferRoleCandidateModalOpen(false)
  }

  const handleRoleSearch = useCallback(
    (value: string) => {
      if (value.length > 0) {
        const filteredRoles = partnerRoles?.filter(role => {
          const formattedValue = value
            .toLowerCase()
            .replaceAll(':', '')
            .replaceAll(' ', '.*')
          const pattern = new RegExp(`(?=.*?${formattedValue})`)

          return (
            `${role.partner.name} ${role.name}`.toLowerCase().match(pattern) &&
            role.id !== partnerRoleId &&
            role.id !== bookedPartnerRoleId
          )
        })
        setFilteredPartnerRoles(mapPartnerRolesToSelectOptions(filteredRoles))
      } else {
        setFilteredPartnerRoles(mapPartnerRolesToSelectOptions(partnerRoles))
      }
    },
    [bookedPartnerRoleId, partnerRoleId, partnerRoles]
  )
  if (isLoading || isLoadingBooked) {
    return null
  }

  return (
    <Modal
      title="Transfer"
      open={isTransferRoleCandidateModalOpen}
      width="400"
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <TransferModalContent
        newPartnerRoleId={newPartnerRoleId}
        handleRoleSearch={handleRoleSearch}
        setNewPartnerRoleId={setNewPartnerRoleId}
        filteredPartnerRoles={filteredPartnerRoles}
      />
    </Modal>
  )
}
