import type { MouseEvent } from 'react'
import React from 'react'

import { ExclamationCircleOutlined } from '@ant-design/icons'

import { App } from 'redesign/components'

import { message } from 'components/Message'

import { TableActions } from 'redesign/components/TableActions'

import { useProfileScreenings } from 'redesign/hooks/useProfileScreenings'
import { useUser } from 'redesign/hooks/useUser'

import type {
  ScreeningsCustomHookData,
  Screening
} from 'redesign/types/ScreeningsSmartlist'

const convertToModalData = (e: Screening) => {
  const { nameEmail, author } = e
  const skills = e?.screenedSkills || []
  return {
    id: e.id,
    author: {
      id: author?.id,
      userName: author?.userName,
      email: author?.email,
      profileURL: author?.profileURL
    },
    body: e.wholeText || '',
    profile: nameEmail?.profileId,
    skills: skills,
    roleFit: e.roleFit,
    xteamFit: e.xteamFit || false,
    roleCandidate: e.roleCandidate,
    createdAt: e.createdAt,
    updatedAt: e.updatedAt,
    publishedAt: e.publishedAt
  }
}

type Props = {
  record: Screening
  customHookData: ScreeningsCustomHookData
}

export const ScreeningActions = (props: Props) => {
  const {
    modal: { confirm }
  } = App.useApp()
  const { data: user } = useUser()
  const { record, customHookData } = props
  const { setProfileId, setSelectedScreening, openModal } = customHookData

  const { deleteScreening } = useProfileScreenings({
    extraQueryKeysToInvalidate: ['smartlistData']
  })

  const onDelete = (event: MouseEvent, screeningId: number) => {
    event.stopPropagation()
    confirm({
      title: 'Are you sure you want to delete this screening?',
      icon: <ExclamationCircleOutlined />,
      okText: 'DELETE',
      cancelText: 'CANCEL',
      onOk: () => {
        if (!record?.author?.id) {
          message.error('Delete failed: screening author is undefined.')
          return
        }
        deleteScreening(screeningId)
      }
    })
  }

  return (
    <TableActions
      user={user}
      record={record}
      onClickEdit={event => {
        event.stopPropagation()
        setProfileId(record?.nameEmail?.profileId)
        setSelectedScreening(convertToModalData(record))
        openModal()
      }}
      onDelete={event => onDelete(event, record.id)}
      canUpdate={Boolean(user.role.capabilities.canUpdateAnyScreening)}
      tableName="screenings"
    />
  )
}
