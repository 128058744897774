import React from 'react'

import dayjs from 'dayjs'

import type { ListObject } from '../SkillList'
import { SkillList } from '../SkillList'

import CavalryAvatar from 'components/CavalryAvatar'
import { Spin } from 'components/Spin'

import type { HotPipelineResponse } from 'redesign/types/HotPipeline'

import useLastNote from './useLastNote'

import {
  avatarBox,
  divider,
  dividerText,
  skillBox,
  skillItems
} from './hotPipelineExpandableContent.module.css'

type HotPipelineExpandableContentProps = {
  record: HotPipelineResponse
}

export const HotPipelineExpandableContent = (props: HotPipelineExpandableContentProps) => {
  const { data, isLoading } = useLastNote(props.record.id)

  if (isLoading) {
    return (
      <div>
        <Spin tip="Loading..." size="large" />
      </div>
    )
  }

  const authorEmail = data[0]?.author?.email || ''
  const authorProfileURL = data[0]?.author?.profileURL || ''
  const createdAt = data[0]?.created_at
    ? dayjs(data[0].created_at).format('YYYY-MM-DD')
    : ''

  const screenedSkills = {
    skills: props.record.screenedSkills as ListObject[],
    type: 'screened'
  }
  const qualifiedSkills = {
    skills: props.record.qualifiedSkills as ListObject[],
    type: 'qualified'
  }

  return (
    <div style={{ margin: '0 30px 30px 30px' }}>
      <div>
        <h3>Most Recent Note:</h3>
      </div>
      {data?.length ? (
        <div>
          <div>{data[0].body}</div>
          <br />
          <div>
            Author:
            <span className={avatarBox}>
              <CavalryAvatar
                email={authorEmail}
                profileURL={authorProfileURL}
              />
            </span>
            Created at: {createdAt}
          </div>
        </div>
      ) : (
        <div>No notes found.</div>
      )}
      <div className={divider}>
        <span className={dividerText}>Skills</span>
      </div>
      <div className={skillBox}>
        Screened Skills:
        <span className={skillItems}>
          <SkillList {...screenedSkills} />
        </span>
      </div>
      <div className={skillBox}>
        Qualified Skills:
        <span className={skillItems}>
          <SkillList {...qualifiedSkills} />
        </span>
      </div>
    </div>
  )
}
