import React, { useEffect, useRef, useState } from 'react'

import { FilterFilled } from '@ant-design/icons'
import { Link as GatsbyLink } from 'gatsby'

import { Actions } from '../containers/RoleCandidates/Actions'
import { ScreeningActions } from '../containers/ScreeningActions/ScreeningActions'
import { ScreeningsOverlay } from '../containers/ScreeningsOverlay/ScreeningsOverlay'
import { TechEvaluationActions } from '../containers/TechEvaluationActions/TechEvaluationActions'
import { TechEvaluationsOverlay } from '../containers/TechEvaluationsOverlay/TechEvaluationsOverlay'

import { ListFilterDropdown } from 'components/ListFilter'
import Pagination from 'components/Pagination'
import StatusTag from 'components/ProfileStatusTag/StatusTag'
import { RangeFilterDropdown } from 'components/RangeFilter'
import { RoleFit } from 'components/RoleFit/RoleFit'
import { RoleFitTag } from 'components/RoleFitTag'
import type { ColumnsType } from 'components/Table'
import { Table } from 'components/Table'
import TextFilterDropdown from 'components/TextFilter'

import { Assignees } from 'redesign/components/Assignees'
import { EntityIcon } from 'redesign/components/EntityIcon'
import { RecentActivities } from 'redesign/components/RecentActivities/RecentActivities'

import { useScreeningsCustomHook } from '../hooks/useScreeningsCustomHook'
import useSmartlistData from '../hooks/useSmartlists/useSmartlistData'
import { useTechEvaluationsCustomHook } from '../hooks/useTechEvaluationsCustomHook'

import { useFeatureFlags } from 'hooks/useFeatureFlags'

import { useLocalStorage } from 'redesign/hooks/useLocalStorage'

import { updateQueryString } from 'utils/filter-helpers'
import { buildRoleFitTooltipText } from 'utils/helpers'

import { convertToParams } from 'redesign/utils/helpers/genericTable'
import { ORIGINS } from 'redesign/utils/roleCandidate'

import type RoleCandidate from 'redesign/types/RoleCandidate'
import type { Screening } from 'redesign/types/ScreeningsSmartlist'
import type {
  Result,
  DropdownFilter,
  AttributeKey,
  AttributeType,
  SmartlistKey,
  SmartlistMap,
} from 'redesign/types/Smartlist'
import type { TechEval } from 'redesign/types/TechEvaluationsSmartlist'
import type User from 'redesign/types/User'

import { CavalryAvatar } from './CavalryAvatar'
import { Draftable } from './Draftable'
import { ExpiredReservationsExpandableContent } from './ExpiredReservationsExpandableContent/ExpiredReservationsExpandableContent'
import type {
  ColumnBuilderProps,
  GenericTableProps,
  ReactComponentType
} from './GenericTable.types'
import { HotPipelineExpandableContent } from './HotPipelineExpandableContent/HotPipelineExpandableContent'
import { Link } from './Link/Link'
import { List } from './List'
import { NameEmail } from './NameEmail/NameEmail'
import { ScreeningsExpandableContent } from './ScreeningsExpandableContent/ScreeningsExpandableContent'
import { TechEvaluationsExpandableContent } from './TechEvaluationsExpandableContent/TechEvaluationsExpandableContent'
import { TopSkills } from './TopSkills'
import { VideoLink } from './VideoLink'
import { XTeamFit } from './XTeamFit/XTeamFit'

const components: Partial<Record<AttributeType, ReactComponentType>> = {
  nameEmail: NameEmail,
  list: List,
  link: Link,
  topSkills: TopSkills,
  videoLink: VideoLink,
  xteamFit: XTeamFit,
  roleFit: RoleFit,
  cavalryAvatar: CavalryAvatar,
  screeningActions: ScreeningActions,
  techEvalActions: TechEvaluationActions,
  recentActivity: ({ value }: { value: RoleCandidate }) => (
    <RecentActivities roleCandidate={value} />
  ),
  expert: ({ value }: { value: boolean }) => (
    <EntityIcon enabled={value} activeTitle="" inactiveTitle="" />
  ),
  draftable: Draftable,
  roleCandidateActions: Actions,
  availabilityStatus: ({ value }: { value: string | { status: string } }) => (
    <StatusTag status={value} />
  ),
  assignees: ({ value }: { value: [User] }) => <Assignees assignees={value} />
}

const smartlistExpandableContents: Record<SmartlistKey, ReactComponentType> = {
  'hot-pipeline': HotPipelineExpandableContent,
  'future-xteamer': HotPipelineExpandableContent,
  'past-xteamer': HotPipelineExpandableContent,
  'expired-reservations': ExpiredReservationsExpandableContent,
  'tech-evaluations': TechEvaluationsExpandableContent,
  screenings: ScreeningsExpandableContent
}

const customSmartlistOverlays = {
  'tech-evaluations': TechEvaluationsOverlay,
  screenings: ScreeningsOverlay
}

const customSmartlistHooks = {
  'tech-evaluations': useTechEvaluationsCustomHook,
  screenings: useScreeningsCustomHook
}

const emptyHook = () => ({
  customHookData: {},
  isCustomHookLoading: false
})

const defaultWidth = 200

const getGenericComponent = ({
  attributes,
  attribute
}: {
  attributes: ColumnBuilderProps['tableSchema']['attributes']
  attribute: AttributeKey
}) => components[attributes[attribute].type] ?? (() => <div>unknown</div>)

const columnRenderer = {
  roleName ({ record }: { record: TechEval | Screening }) {
    const { partnerRole } = record.roleCandidate
    return (
      partnerRole.id !== null && (
        <GatsbyLink to={`/role?id=${partnerRole.id}`}>
          {partnerRole.name}
        </GatsbyLink>
      )
    )
  },
  roleFit ({ value, record }: {
    value: TechEval['roleFit'] | Screening['roleFit'];
    record: TechEval | Screening
  }) {
    return (
      value !== null && (
        <RoleFitTag
          isFit={value}
          tooltipText={buildRoleFitTooltipText({
            isFit: Boolean(value),
            userName: record.roleCandidate.assignee.username,
            date: record.roleCandidate.updatedAt,
            context: 'recording' in record ? 'Technical Evaluation' : 'Screening'
          })}
        />
      )
    )
  },
  // @TODO(TS migration): we'll need to dynamically type params marked as any
  // and I assume this type param (K) might come in handy. But feel free to change
  // the approach as needed.
  // Example: default<K extends SmartlistKey>({...})
  default ({
    value,
    record,
    attr,
    attributes,
    attribute,
    customHookData,
    onExpandRow,
    expandedRowKeys,
  }: {
    value: any
    record: any
    attr: any
    attributes: ColumnBuilderProps['tableSchema']['attributes']
    attribute: AttributeKey // Key of the attr
    customHookData: any
    onExpandRow: (expanded: boolean, row: any) => void
    expandedRowKeys: number[]
    smartlistKey: SmartlistKey
  }) {
    if (['string', 'number'].includes(attr.type)) {
      return <div>{value}</div>
    }

    const GenericComponent = getGenericComponent({ attributes, attribute })
    return (
      <GenericComponent
        value={value}
        record={record}
        customHookData={customHookData}
        onExpandRow={onExpandRow}
        isRowExpanded={expandedRowKeys.includes(record.id)}
        {...attr}
      />
    )
  }
}

const columnBuilder = ({
  tableSchema,
  filters,
  filterInputRef,
  onExpandRow,
  expandedRowKeys,
  customHookData,
  features
}: ColumnBuilderProps) => {
  const { attributes, key: attributeKey } = tableSchema
  const columns: ColumnsType<Result> = []

  type TargetSmartlistData = SmartlistMap[typeof attributeKey]

  for (const key in attributes) {
    const attribute: AttributeKey = key as AttributeKey
    if (
      ['roleName', 'roleFit'].includes(attribute) &&
      !features?.SCREEN_QUALIFY_ROLE_CANDIDATES
    ) {
      continue
    }

    const attr = { ...attributes[attribute] }
    const col = {
      key: attribute,
      dataIndex: attribute,
      title: attr.title,
      align: attr.align || 'left',
      width: attr?.width || defaultWidth,
      sorter: attr?.sortable ?? false,
      render: (value: any, record: TargetSmartlistData) => {
        // @TODO(TS migration)
        // @ts-expect-error we need to have a discriminated union of
        // possible attributes per smartlist type so we can type values correctly.
        const rendererFn = columnRenderer[attribute] ?? columnRenderer.default<typeof attributeKey>
        return rendererFn({
          value,
          record,
          attr,
          attributes,
          attribute,
          customHookData,
          onExpandRow,
          expandedRowKeys,
        })
      }
    }

    if (attr.filter) {
      // @TODO(TS migration) It's impossible to read this SWITCH statement.
      // It must be simplified and extracted outside the component.
      switch (typeof attr.filter) {
        case 'boolean':
          // @TODO(TS migration)
          // @ts-expect-error we need to have a discriminated union of
          // possible attributes per smartlist type so we can type attribute correctly.
          col.filteredValue = filters[attribute]
          // @TODO(TS migration)
          // @ts-expect-error we need to have a discriminated union of
          // possible attributes per smartlist type so we can type attribute correctly.
          col.filterIcon = isFiltered => (
            <FilterFilled
              data-testid={attribute + '-filter-icon'}
              className={isFiltered && 'active-state'}
            />
          )
          // @TODO(TS migration)
          // @ts-expect-error we need to have a discriminated union of
          // possible attributes per smartlist type so we can type attribute correctly.
          col.filterDropdown = props => (
            <TextFilterDropdown
              {...props}
              // @TODO(TS migration)
              // @ts-expect-error we need to have a discriminated union of
              // possible attributes per smartlist type so we can type attribute correctly.
              ref={filterInputRef[attribute] || null}
              filterKey={attribute}
              filters={filters}
              placeholderText={attr.placeholder}
            />
          )
          // @TODO(TS migration)
          // @ts-expect-error we need to have a discriminated union of
          // possible attributes per smartlist type so we can type attribute correctly.
          col.onFilterDropdownVisibleChange = visible => {
            if (!visible) {
              return
            }

            // @TODO(TS migration)
            // @ts-expect-error we need to have a discriminated union of
            // possible attributes per smartlist type so we can type attribute correctly.
            if (!filterInputRef[attribute]) {
              return
            }
            if (filterInputRef.current[attribute]) {
              const filterInputNode: HTMLInputElement = filterInputRef.current[attribute]
              setTimeout(() => {
                filterInputNode.select()
                filterInputNode.focus()
              }, 100)
            }
          }
          break

        case 'object': {
          const dropdownFilters: DropdownFilter[] = []
          if (Array.isArray(attr.filter.possibleValues)) {
            for (const option of attr.filter.possibleValues) {
              dropdownFilters.push({
                text: option.label,
                value: option.value
              })
            }
          } else if (
            'items' in attr.filter?.possibleValues &&
            Array.isArray(attr.filter.possibleValues.items)
          ) {
            for (const option of attr.filter.possibleValues.items) {
              dropdownFilters.push({
                text: option.text,
                value: option.value
              })
            }
          }

          // @TODO(TS migration)
          // @ts-expect-error we need to have a discriminated union of
          // possible attributes per smartlist type so we can type attribute correctly.
          col.filters = dropdownFilters
          // @TODO(TS migration)
          // @ts-expect-error we need to have a discriminated union of
          // possible attributes per smartlist type so we can type attribute correctly.
          col.filteredValue = filters[attribute]
          // @TODO(TS migration)
          // @ts-expect-error we need to have a discriminated union of
          // possible attributes per smartlist type so we can type attribute correctly.
          col.filterIcon = isFiltered => (
            <FilterFilled
              data-testid={attribute + '-filter-icon'}
              className={isFiltered && 'active-state'}
            />
          )
          if (attr.filter?.range) {
            // @TODO(TS migration)
            // @ts-expect-error we need to have a discriminated union of
            // possible attributes per smartlist type so we can type attribute correctly.
            col.filterDropdown = props => (
              <RangeFilterDropdown
                lowerBound={dropdownFilters[0].value}
                upperBound={dropdownFilters[dropdownFilters.length - 1].value}
                tableFilters={filters}
                filterKey={attribute}
                {...props}
              />
            )
          } else {
            const multipleSelection = attr.filter?.multiple || false
            // @TODO(TS migration)
            // @ts-expect-error we need to have a discriminated union of
            // possible attributes per smartlist type so we can type attribute correctly.
            col.filterMultiple = multipleSelection
            // @TODO(TS migration)
            // @ts-expect-error we need to have a discriminated union of
            // possible attributes per smartlist type so we can type attribute correctly.
            col.filterDropdown = props => (
              <ListFilterDropdown
                {...props}
                filterKey={attribute}
                filters={dropdownFilters}
                filterMultiple={multipleSelection}
              />
            )
          }
        }
      }
    }
    // @TODO(TS migration)
    // @ts-expect-error we need to have a discriminated union of
    // possible attributes per smartlist type so we can type col correctly.
    columns.push(col)
  }

  return columns
}

export const GenericTable = ({
  smartlistKey,
  tableSchema,
  queryParams,
}: GenericTableProps) => {
  const { features } = useFeatureFlags()
  const [filters, setFilters] = useState(queryParams.filters)
  const [sorter, setSorter] = useState(queryParams.sorter)
  const [pagination, setPagination] = useState(queryParams.pagination)

  const [localStorageValue, setLocalStorageValue] = useLocalStorage('origin')

  const { data, isLoading, total } = useSmartlistData({
    smartlistKey,
    filters,
    sorter,
    pagination
  })

  // @TODO(TS migration)
  // @ts-expect-error fix this error
  const GenericRowExpander = smartlistExpandableContents[smartlistKey]

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  // @TODO(TS migration)
  // @ts-expect-error type 'row' param properly
  const onExpandRow = (expanded: boolean, row) => {
    if (expanded) {
      setExpandedRowKeys(state => [...state, row.id])
    } else {
      setExpandedRowKeys(state => state.filter(id => id !== row.id))
    }
  }

  // @TODO(TS migration)
  // @ts-expect-error fix this error
  const customHook = customSmartlistHooks[smartlistKey] || emptyHook
  const { customHookData, isCustomHookLoading } = customHook()

  // @TODO(TS migration)
  // @ts-expect-error fix this error
  const CustomOverlay = customSmartlistOverlays[smartlistKey]

  const filterInputRef = useRef({})
  const columns = columnBuilder({
    tableSchema,
    filters,
    filterInputRef,
    expandedRowKeys,
    onExpandRow,
    customHookData,
    features
  })

  const { attributes } = tableSchema

  const expandable = {
    // @TODO(TS migration)
    // @ts-expect-error type 'record' correctly
    expandedRowRender: record => (
      <GenericRowExpander record={record} {...attributes} />
    ),
    rowExpandable: () => Boolean(GenericRowExpander),
    expandRowByClick: true
  }

  // @TODO(TS migration)
  // @ts-expect-error type params correctly
  const onPaginationChange = (current, pageSize) => {
    setPagination({ pageSize, current })
  }

  // @TODO(TS migration)
  // @ts-expect-error type params correctly
  const onTableChange = (newPagination, newFilters, sorter) => {
    setPagination({ ...pagination, ...newPagination })
    setFilters({ ...filters, ...newFilters })
    setSorter(sorter)
  }

  // Update query string
  useEffect(() => {
    const queryParams = convertToParams({
      filters,
      sorter,
      pagination,
      key: smartlistKey
    })
    updateQueryString(queryParams)
  }, [data, filters, pagination, smartlistKey, sorter])

  // Handle an invalid pagination state (i.e. current page is past the new total number of results)
  useEffect(() => {
    if (total !== null) {
      const { current } = pagination
      current > 1 &&
        data.length === 0 &&
        setPagination({ ...pagination, current: 1 })
    }
  }, [data.length, pagination, total])

  const minTableWidth = columns.reduce(
    (acc, col) => acc + (Number(col.width) || 300),
    0
  )

  const type = ORIGINS.SMARTLIST.type.find(types => types.indexOf(smartlistKey) > 0)

  return (
    <>
      <Pagination onChange={onPaginationChange} {...pagination} total={total} />
      <Table
        dataSource={data}
        columns={columns}
        expandable={expandable}
        onExpand={onExpandRow}
        expandedRowKeys={expandedRowKeys}
        size="small"
        scroll={{ x: minTableWidth }}
        loading={isLoading || isCustomHookLoading}
        pagination={false}
        onChange={onTableChange}
        onRow={record => ({
          onClick: event => {
            event.stopPropagation()
            setLocalStorageValue({
              ...localStorageValue,
              [record.nameEmail.profileId]: {
                type,
                record,
                url: window.location.href
              }
            })
          },
          onContextMenu: () => {
            setLocalStorageValue({
              ...localStorageValue,
              [record.nameEmail.profileId]: {
                type,
                record,
                url: window.location.href
              }
            })
          }
        })}
      />
      <Pagination onChange={onPaginationChange} {...pagination} total={total} />
      {CustomOverlay && !isLoading && !isCustomHookLoading && (
        <CustomOverlay customHookData={customHookData} />
      )}
    </>
  )
}
